import Analytics, { AnalyticsInstance } from 'analytics';
import { useAtom } from 'jotai';
import mixpanelPlugin from '@analytics/mixpanel';
import { createContext, useContext } from 'react';
import { meterTypeAtom } from './state';

export function createAnalytics(): AnalyticsInstance {
  return Analytics({
    app: 'mit-self-service-web-journeys',
    plugins: [
      mixpanelPlugin({
        token: '2584da2c9536e1d2897801ccad9d6190',
      }),
    ],
  });
}

export const AnalyticsContext = createContext<AnalyticsInstance | undefined>(undefined);

export function useTrack(): (
  event: string,
  payload?: Record<string, unknown>,
  isUiEvent?: boolean,
) => void {
  const analytics = useContext(AnalyticsContext);
  const [meterType] = useAtom(meterTypeAtom);
  const appName = 'smh';
  return (event, payload = {}, isUiEvent = true) => {
    const prefix = isUiEvent ? 'UI' : 'App';
    analytics?.track(`${appName}:${prefix}:${event}`, {
      ...payload,
      isUiEvent,
      meterType,
      appName,
    });
  };
}
